import React from "react";

const NewsLetter = () => {
  return (
    <div className="w-full bg-bluee">
      <div className="max-w-xl mx-auto py-16">
        <h1 className="text-center text-white title text-3xl pb-1">
          Get Started Today – Elevate Your Campaign with Data & AI
        </h1>
        <p className="text-center text-white text-lg pb-8">
          Contact us to learn how Astra Intel’s cutting-edge solutions can
          propel your campaign forward. Explore our exclusive beta and alpha AI
          programs and discover the future of data-driven advocacy.
        </p>
        <div className="flex justify-center">
        <a
            href="mailto:info@astraintel.co"
            className="text-black mb-10 text-lg px-10 py-4 font-semibold border-2 border-black cursor-pointer hover:text-blue-500 hover:border-blue-500 transition-all"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
