import React from "react";
import {GrFacebookOption} from "@react-icons/all-files/gr/GrFacebookOption";
import {GrTwitter} from "@react-icons/all-files/gr/GrTwitter";
import {GrLinkedinOption} from "@react-icons/all-files/gr/GrLinkedinOption";
import {FaInstagram} from "@react-icons/all-files/fa/FaInstagram";
import {AiOutlineMedium} from "@react-icons/all-files/ai/AiOutlineMedium";
import {IoLocationSharp} from "@react-icons/all-files/io5/IoLocationSharp";
import {GrMail} from "@react-icons/all-files/gr/GrMail";
import {FaYoutube} from "@react-icons/all-files/fa/FaYoutube";
import {Link} from "gatsby";
import logo from "../images/logo.svg";
const Footer = () => {
  return (
    <div className=" bg-black">
      <div className=" w-full md:max-w-7xl mx-auto px-10 py-20">
        <div className="grid grid-cols-4 gap-14">
          <div className=" w-96">
            
            <Link to="/">
              <img className="w-44 mb-5" src={logo} alt="Smentic Bridges" />
            </Link>
            <p className="text-white text-sm pb-1 hover:text-btnColor transition-all">
              Astral Intel</p>
            <p className="text-white text-sm pb-1 hover:text-btnColor transition-all">
            Arlington, VA
            </p>
            {/* <div>
              <ul className="mb-8">
                <li className="text-white text-sm pb-1 hover:text-btnColor transition-all">
                  QuantumBlack
                </li>
                <li className="text-white text-sm pb-1 hover:text-btnColor transition-all">
                  100 Museum St
                </li>
                <li className="text-white text-sm pb-1 hover:text-btnColor transition-all">
                  Holborn
                </li>
                <li className="text-white text-sm pb-1 hover:text-btnColor transition-all">
                  London WC1A 1PB
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li className="text-white text-sm pb-1 hover:text-btnColor transition-all">
                  +44 20 3763 6360
                </li>
                <li className="text-white text-sm pb-1 hover:text-btnColor transition-all">
                  General enquiries: info@quantumblack.com
                </li>
                <li className="text-white text-sm pb-1 hover:text-btnColor transition-all">
                  Recruitment enquiries: qb-recruiting@mckinsey.com
                </li>
                <li className="text-white text-sm pb-1 hover:text-btnColor transition-all">
                  London WC1A 1PB
                </li>
              </ul>
            </div> */}
          </div>
          <div className="col-span-2 mt-12">
            <ul className="flex gap-20">
              <li className="text-white text-md pb-3 hover:text-btnColor transition-all">
                <Link to="/about">About Us</Link>
              </li>
              
              <li className="text-white text-md pb-3 hover:text-btnColor transition-all">
                <Link to="/work">Services</Link>
              </li>
              {/* <li className="text-white text-md pb-3 hover:text-btnColor transition-all">
                <Link to="/contact">Labs</Link>
              </li>
              <li className="text-white text-md pb-3 hover:text-btnColor transition-all">
                <Link to="/about">Careers</Link>
              </li>
              <li className="text-white text-md pb-3 hover:text-btnColor transition-all">
                <Link to="/work">Our community</Link>
              </li> */}
              <li className="text-white text-md pb-3 hover:text-btnColor transition-all">
                <Link to="/contact">Contact Us</Link>
              </li>
              
            </ul>
            <ul>
            
            </ul>
            <ul className="flex gap-16">
            <li className="text-white text-sm pb-3 hover:text-btnColor transition-all pl-20">
                <Link to="/privacy">Privacy policy</Link>
              </li>
              <li className="text-white text-sm pb-3 hover:text-btnColor transition-all">
                <Link to="/terms">Terms of use</Link>
              </li>
              {/* <li className="text-white text-sm pb-3 hover:text-btnColor transition-all">
                <Link to="/about">Terms of use</Link>
              </li> */}
              {/* <li className="text-white text-sm pb-3 hover:text-btnColor transition-all">
                <Link to="/work">Privacy policy</Link>
              </li> */}
              {/* <li className="text-white text-sm pb-3 hover:text-btnColor transition-all">
                <Link to="/contact">Accessibility statement</Link>
              </li>
              <li className="text-white text-sm pb-3 hover:text-btnColor transition-all">
                <Link to="/about">Cookie policy</Link>
              </li> */}
            </ul>
          </div>
          <div className="mt-12">
            <div className="flex flex-row">
              {/* <a
                href="#"
                className=" text-white rounded-full border-2 border-white mx-1.5 bg-socialBg"
              >
                <AiOutlineMedium className="text-lg mx-2 my-2" />
              </a> */}
              <a
                href="https://www.linkedin.com/company/astra-intel/"
                className=" text-white rounded-full border-2 border-white mx-1.5 bg-socialBg"
              >
                <GrLinkedinOption className="text-lg mx-2 my-2" />
              </a>
              {/* <a
                href="#"
                className=" text-white rounded-full border-2 border-white mx-1.5 bg-socialBg"
              >
                <GrTwitter className="text-lg mx-2 my-2" />
              </a>
              <a
                href="#"
                className=" text-white rounded-full border-2 border-white mx-1.5 bg-socialBg"
              >
                <FaInstagram className="text-lg mx-2 my-2" />
              </a>
              <a
                href="#"
                className=" text-white rounded-full border-2 border-white mx-1.5 bg-socialBg"
              >
                <GrFacebookOption className="text-lg mx-2 my-2" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
