import React from "react";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
import NewsLetter from "./layout/NewsLetter";

 const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
         {children}
       <NewsLetter />  
      <Footer />
    </div>
  );
};
export default Layout;