import React,{useEffect} from "react";
import {Disclosure} from "@headlessui/react";
import {FiMenu} from "@react-icons/all-files/fi/FiMenu";
import {FiX} from "@react-icons/all-files/fi/FiX";
import logo from "../images/logo.svg";
import {Link} from "gatsby";
const navigation = [
  {name: "Home", href: "/", current: true},
  {name: "About us", href: "/about", current: false},
  {name: "Services", href: "/work", current: false},
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {

useEffect(() => {
  window.addEventListener("scroll", resizeHeaderOnScroll);
}, [])
const resizeHeaderOnScroll = () => {
  const distanceY = window.pageYOffset || document.documentElement.scrollTop,
    shrinkOn = 50,
    headerEl = document.getElementById("logo");

  if (distanceY > shrinkOn) {
    headerEl.classList.add("logoShrink");
  } else {
    headerEl.classList.remove("logoShrink");
  }
}
  return (
    <div className="relative overflow-hidden z-10">
      <Disclosure as="nav" className=" bg-headerBg fixed left-0 right-0 top-0">
        {({open}) => (
          <>
            <div className="max-w-7xl mx-auto   px-2 md:px-6 lg:px-8">
              <div id='logo'  className="relative flex items-center justify-between   h-24">
                <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <FiX className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <FiMenu className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-start lg:justify-end">
                  <div className="flex-shrink-0 flex items-center ">
                    <img
                      className="block lg:hidden  w-40"
                      src={logo}
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block logoo w-64 md:absolute md:left-5"
                      src={logo}
                      alt="Workflow"
                    />
                  </div>
                  <div className="hidden md:block md:ml-6">
                    <div className="flex space-x-1">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? " text-white  hover:text-white"
                              : " text-white  hover:text-white transition-all font-normal ",
                            "px-3 py-2 rounded-md text-md font-normal"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 ">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current
                        ? " text-white "
                        : "  text-white  hover:text-white transition-all font-normal",
                      "block px-3 py-2 rounded-md text-base font-normal"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
